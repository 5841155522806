<template>
  <div class="receive-tab">
    <div class="d-flex justify-center pt-4">
      <v-img :src="qrLink" alt="QR Code" max-width="200" />
    </div>

    <div class="mt-10">{{ $t("wallet.yourDepositAddress") }}</div>

    <div
      id="address"
      class="text--secondary cursor-pointer"
      style="overflow-wrap: anywhere"
      @click="copyAddress"
    >
      {{ address }}
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    address() {
      return "0x843C6B52aeA14305d2b9c8ab781A1Bf714e98234";
    },

    qrLink() {
      return `https://api.qrserver.com/v1/create-qr-code/?size=200x200&color=ffffff&bgcolor=081525&data=${this.address}`;
    },
  },

  methods: {
    copyAddress() {
      navigator.clipboard.writeText(this.address);
      this.$toast.success(this.$t("wallet.addressCopied"));
    },
  },
};
</script>

<style></style>
